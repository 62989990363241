<template>
  <div>
    <b-row class="back-action-link mb-3">
      <b-col>
        <a href="javascript:void(0)" id="back-to-recreation-categories" class="theme-font-bold" role="button" @click="$emit('onShowRecreationClasses', false)"
          ><ChevronLeftBlue class="mr-1 mt-n05 chevron-icon" />{{ translateLanguage('Lnk_Recreation_widget_Back') }}</a
        >
      </b-col>
    </b-row>
    <b-row v-if="recreationWidgetSelectedProgram">
      <b-col>
        <h4 class="font-18 theme-font-bold" id="selected-recreation-program">
          {{ recreationWidgetSelectedProgram.name }}
        </h4>
      </b-col>
    </b-row>
    <FilterForm search-type="classes" :calendar-type="recreationWidgetSelectedProgram.calendarType" />
    <LoaderIcon v-if="recreationClassesLoading" />
    <template v-else>
      <template v-if="!recreationWidgetFilterFormError.hasError && recreationWidgetFilterFormError.showSearchResult">
        <template v-if="recreationClasses && recreationClasses.result.length">
          <b-form-row class="mt-3 recreation-classess" v-for="(classType, cTIndex) in recreationClasses.result" :key="cTIndex">
            <b-col cols="12" class="border-bottom-1 d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <h4 class="font-18 theme-font-bold pt-3 pb-1">
                  {{ getTitle(classType) }}
                </h4>
                <div v-if="classType.description">
                  <InfoButtonTogglePopover class="px-1 mt-n05" :scale="1" :description="classType.description" />
                </div>
              </div>
              <div v-if="recreationClasses.calendarType !== recreationBookingEnums.SINGLEBOOKING">
                <a href="javascript:void(0)" class="theme-font-bold text-decoration-none" id="show-hide-toggle" @click="expandDetails(classType)"
                  >{{ classType.show ? translateLanguage('Lnk_Recreation_widget_Hide') : translateLanguage('Lnk_Recreation_widget_Show') }}
                  <ChevronUpBlue class="chevron-icon" v-if="classType.show" />
                  <ChevronDownBlue class="chevron-icon" v-else
                /></a>
              </div>
            </b-col>
            <template v-if="isShowClasses(classType.show)">
              <b-col cols="12 recreation-class-item" v-for="(classItem, cIndex) in getClassResult(classType)" :key="cIndex">
                <b-form-row class="border-bottom-1 py-2">
                  <b-col cols="9" lg="10">
                    <b-form-row class="pb-1">
                      <b-col class="d-flex align-items-center">
                        <div class="theme-font-bold">
                          {{ getClassTitle(classItem) }}
                        </div>
                        <div v-if="classItem.description">
                          <InfoButtonTogglePopover class="pl-1 mt-n05" :scale="1" :description="classItem.description" />
                        </div>
                        <div class="color-gray pl-1" v-if="classItem.hasCapacity && classItem.remaining !== null">
                          ({{ Number(classItem.remaining) ? `${classItem.remaining} spot${Number(classItem.remaining) > 1 ? 's' : ''} left` : `Full` }})
                        </div>
                        <div class="color-gray pl-1" v-if="classItem.availability">({{ classItem.availability }})</div>
                      </b-col>
                    </b-form-row>
                    <b-form-row class="recreation-class-icons">
                      <b-col lg="6" class="d-flex align-items-top" v-if="classItem.sessionDates">
                        <div>
                          <CalendarIcon class="color-gray mr-1 mt-n05" />
                        </div>
                        <div>
                          {{ classItem.sessionDates }}
                        </div>
                      </b-col>
                      <b-col lg="4" class="d-flex align-items-top" v-if="classItem.convertedStartTime && classItem.convertedEndTime">
                        <div>
                          <ClockIcon class="color-gray mr-1 mt-n05" />
                        </div>
                        <div>{{ classItem.convertedStartTime }}</div>
                        - {{ classItem.convertedEndTime }}
                      </b-col>
                      <b-col :lg="getLocationNameClass" class="d-flex align-items-top" v-if="classItem.locationName">
                        <div>
                          <MapPinIcon class="color-gray mr-1 mt-n05" />
                        </div>
                        <div>{{ classItem.locationName }}</div>
                      </b-col>
                      <b-col lg="3" class="d-flex align-items-top" v-if="classItem.fee">
                        <div>
                          <DollarIcon class="color-gray mr-1 mt-n05" />
                        </div>
                        <div>{{ classItem.fee }}</div>
                      </b-col>
                    </b-form-row>
                  </b-col>
                  <b-col cols="3" lg="2" class="d-flex align-items-center justify-content-lg-end action-buttons">
                    <b-button
                      class="btn-theme-height d-flex align-items-center justify-content-center"
                      :disabled="classItem.isClosed"
                      @click="classItem.isClosed ? $event.preventDefault() : redirectTo(classItem.registrationLink)"
                      :class="[classItem.isClosed ? 'btn-gray cursor-default' : 'btn-black']"
                    >
                      {{ getButtonText(classItem) }}
                    </b-button>
                  </b-col>
                </b-form-row>
              </b-col>
            </template>
          </b-form-row>
        </template>
        <div v-else class="text-center">
          <SadComputer />
          <div class="py-2">{{ translateLanguage('Txt_Recreation_widget_No_results_found') }}</div>
        </div>
      </template>
    </template>
  </div>
</template>
<script>
import axios from 'axios'
import { mapState } from 'vuex'
import moment from 'moment'
import ChevronLeftBlue from '../../../assets/svg/chevron-left-blue.svg'
import ChevronUpBlue from '../../../assets/svg/chevron-up-blue.svg'
import ChevronDownBlue from '../../../assets/svg/chevron-down-blue.svg'
import SadComputer from '../../../assets/svg/sad_computer.svg'
import CalendarIcon from '../../../assets/svg/calendar-icon.svg'
import ClockIcon from '../../../assets/svg/clock-icon.svg'
import DollarIcon from '../../../assets/svg/dollar-icon.svg'
import MapPinIcon from '../../../assets/svg/map-pin-icon.svg'
import InfoButtonTogglePopover from '../../../components/common/InfoButtonTogglePopover.vue'
import LoaderIcon from '../../../assets/svg/loader.svg'
import { useFormatPayload } from '../../../composables/useFormatPayload'
import cloneDeep from 'lodash/cloneDeep'
import { RecreationBookingEnums } from '../../../utilities/constants'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'

export default {
  name: 'RecreationClasses',
  setup() {
    const { isLoading: recreationClassesLoading, response: recreationClassesRes, error: recreationClassesError, callApi: recreationClassesApi } = useAxios()
    const { formatRequestPayload } = useFormatPayload()

    return { formatRequestPayload, recreationClassesLoading, recreationClassesRes, recreationClassesError, recreationClassesApi }
  },
  data() {
    return {
      recreationClasses: null,
      recreationBookingEnums: RecreationBookingEnums,
    }
  },
  inject: ['mode', 'classesURL'],
  components: {
    ChevronLeftBlue,
    ChevronUpBlue,
    ChevronDownBlue,
    CalendarIcon,
    ClockIcon,
    DollarIcon,
    MapPinIcon,
    InfoButtonTogglePopover,
    LoaderIcon,
    SadComputer,
    FilterForm: () => import('./FilterForm.vue'),
  },
  mounted() {
    this.getRecreationClasses(this.recreationWidgetFilterForm)
  },
  computed: {
    getTitle() {
      return (classType) => {
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.SINGLEBOOKING) {
          return moment(classType.date, 'MM/DD/YY').format('MMM Do')
        }
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.COURCES) {
          return classType.name
        }
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.FACILITYBOOKING) {
          return classType.locationName
        }
      }
    },
    getClassResult() {
      return (classType) => {
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.SINGLEBOOKING) {
          return classType.classes
        }
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.COURCES) {
          return classType.sessions
        }
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.FACILITYBOOKING) {
          return classType.facilities
        }
      }
    },
    getClassTitle() {
      return (classItem) => {
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.SINGLEBOOKING) {
          return classItem.name
        }
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.COURCES) {
          return classItem.subject
        }
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.FACILITYBOOKING) {
          return classItem.facility
        }
      }
    },
    getButtonText() {
      return (classItem) => {
        if (this.recreationClasses.calendarType === this.recreationBookingEnums.FACILITYBOOKING) {
          return this.translateLanguage('Btn_Recreation_widget_Choose')
        } else {
          return classItem.isClosed ? this.translateLanguage('Btn_Recreation_widget_Closed') : this.translateLanguage('Btn_Recreation_widget_Book_now')
        }
      }
    },
    isShowClasses() {
      return (show) => show || this.recreationClasses.calendarType === this.recreationBookingEnums.SINGLEBOOKING
    },
    getLocationNameClass() {
      return this.recreationClasses.calendarType === this.recreationBookingEnums.SINGLEBOOKING ? 5 : 6
    },
    ...mapState({
      recreationWidgetSelectedProgram: (state) => state.shared.recreationWidgetSelectedProgram,
      recreationWidgetFilterForm: (state) => state.shared.recreationWidgetFilterForm,
      recreationWidgetClassesFilterForm: (state) => state.shared.recreationWidgetClassesFilterForm,
      recreationWidgetFilterFormError: (state) => state.shared.recreationWidgetFilterFormError,
    }),
  },
  methods: {
    expandDetails(classType) {
      classType.show = !classType.show
      this.$forceUpdate()
    },
    async getRecreationClasses(form) {
      const cloneForm = cloneDeep(form)
      if (form && this.recreationWidgetSelectedProgram.calendarType === this.recreationBookingEnums.FACILITYBOOKING) {
        cloneForm.startTime = null
        cloneForm.endTime = null
      }
      const postObj = this.formatRequestPayload(cloneForm)
      postObj.programName = this.recreationWidgetSelectedProgram.name
      const url = this.mode === 'Preview' ? this.classesURL : ServiceUrls.getRecreationWidgetClasses
      await this.recreationClassesApi({ method: 'post', url, data: postObj })
      if (this.recreationClassesRes) {
        this.recreationClasses = this.recreationClassesRes
        if (this.recreationClasses && !!this.recreationClasses.result.length) {
          this.recreationClasses.result[0].show = true
        }
      } else {
        this.recreationClasses = null
      }
      if (this.recreationClassesError) {
        if (!axios.isCancel(this.recreationClassesError)) {
          this.recreationClasses = null
        }
      }
    },
    redirectTo(url) {
      window.open(url)
    },
  },
  watch: {
    recreationWidgetClassesFilterForm: {
      deep: true,
      handler(newValue) {
        this.getRecreationClasses(newValue)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.action-buttons {
  .btn {
    width: 108px;
  }
}
</style>
