import { render, staticRenderFns } from "./RecreationClasses.vue?vue&type=template&id=6339a6e8&scoped=true&"
import script from "./RecreationClasses.vue?vue&type=script&lang=js&"
export * from "./RecreationClasses.vue?vue&type=script&lang=js&"
import style0 from "./RecreationClasses.vue?vue&type=style&index=0&id=6339a6e8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../CitizenPortalUI_Admin/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6339a6e8",
  null
  
)

export default component.exports